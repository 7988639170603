import { Link } from '@pushpay/link';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { useShowNotification } from '@src/components/notification';
import { useTranslation } from '@src/i18n/translation';

const useStyles = createUseStyles((theme: Theme) => ({
	link: {
		marginLeft: theme.SPACING.XSMALL,
	},
}));

export function useServerErrorNotification() {
	const classes = useStyles();
	const { translate } = useTranslation('common');
	const { translate: translateAppDesign } = useTranslation('appDesign');
	const showNotification = useShowNotification();
	const showGenericNetworkAndServerError = () => {
		showNotification({
			isOpen: true,
			type: 'error',
			title: translate('errors.title.error'),
			content: translate('errors.text.networkOrServerError'),
		});
	};

	const showStudioCServiceUnreachableError = () => {
		showNotification({
			isOpen: true,
			type: 'error',
			title: translateAppDesign('settings.container.studioC.studioCFailedErrorTitle'),
			content: (
				<>
					{translateAppDesign('settings.container.studioC.studioCFailedErrorMessage')}
					<Link
						className={classes.link}
						href="https://studiochelp.zendesk.com/hc/en-us"
						target="_blank"
						openInNewTab
					>
						{translateAppDesign('settings.container.studioC.studioCContact')}
					</Link>
				</>
			),
			persistent: true,
		});
	};

	return {
		showGenericNetworkAndServerError,
		showStudioCServiceUnreachableError,
	};
}
