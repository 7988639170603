import { useRef } from 'react';

import { Button } from '@pushpay/button';
import { DeleteIcon } from '@pushpay/iconography';
import Modal from '@pushpay/modal';
import { clsx } from '@pushpay/styles';

import { Link } from '@src/components/link';
import { useShowNotification } from '@src/components/notification';
import { useTranslation } from '@src/i18n';
import { ContainerCardType } from '@src/utils/cardType';

import useStyles from '../modalStyles';

export type DeleteModalProps = {
	isDeletable?: boolean;
	translatedCardType: ContainerCardType;
	isModalOpen: boolean;
	setIsModalOpen: (isOpen: boolean) => void;
	onDelete?: () => void;
	parentPath?: string;
};

export const DeleteModal = ({
	isDeletable,
	translatedCardType,
	isModalOpen,
	setIsModalOpen,
	onDelete,
	parentPath,
}: DeleteModalProps) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');
	const errorTranslate = useTranslation('common').translate;
	const confirmRef = useRef(null);
	const showNotification = useShowNotification();
	let cardType;

	switch (translatedCardType) {
		case ContainerCardType.CCB:
			cardType = translate('cardType.ccbModule');
			break;
		case ContainerCardType.Item:
			cardType = translate('cardType.item');
			break;
		case ContainerCardType.Container:
		default:
			cardType = translate('cardType.container');
			break;
	}

	const onClickHandler = () => {
		setIsModalOpen(false);
		if (!isDeletable) {
			showNotification({
				type: 'error',
				title: errorTranslate('errors.title.error'),
				content: errorTranslate('errors.text.deleteContainerError'),
			});
		} else if (onDelete) {
			onDelete();
		}
	};

	const confirmButton = (
		<Button ref={confirmRef} displayStyle="primary" type="button" onClick={onClickHandler}>
			{translate('deleteModal.confirm')}
		</Button>
	);

	let deleteMessage = translate('deleteModal.defaultDeleteText');
	if (translatedCardType === 'item') {
		deleteMessage = translate('deleteModal.itemCardDeleteText');
	} else if (translatedCardType === 'container') {
		deleteMessage = translate('deleteModal.containerCardDeleteText');
	}

	return (
		<Modal
			defaultFocus={confirmRef}
			isOpen={isModalOpen}
			onClick={e => e.stopPropagation()}
			onClickOutside={() => setIsModalOpen(false)}
		>
			<div className={classes.content}>
				<DeleteIcon classes={{ root: classes.icon }} data-pp-at-target="delete-icon" />
				<div className={classes.header}>{`${translate('kebabMenuItems.delete')} ${cardType}?`}</div>
				<div className={clsx(classes.cardText, classes.text)}>{deleteMessage}</div>
				<div className={classes.buttons}>
					<Button displayStyle="secondary" type="button" onClick={() => setIsModalOpen(false)}>
						{translate('deleteModal.cancel')}
					</Button>
					{parentPath && isDeletable ? (
						<Link displayStyle="unstyled" to={parentPath}>
							{confirmButton}
						</Link>
					) : (
						confirmButton
					)}
				</div>
			</div>
		</Modal>
	);
};
