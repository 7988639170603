import {
	useDeleteContainerMutation,
	useDeleteItemMutation,
	useGetContainerSettingsLazyQuery,
} from '@src/graphql/generated';
import { CONTAINER_CHILDREN_PAGING_SIZE } from '@src/pages/constants';
import { assertNever } from '@src/utils';
import { ContainerCardType } from '@src/utils/cardType';

type DeleteHandlerType = {
	id: string;
	type: ContainerCardType;
	parentId?: string | null;
	onCompleted?: () => void;
};

export function useItemAndContainerDeleteMutation({
	organizationKey,
	platformCampusKey,
	applicationId,
}: {
	organizationKey: string;
	platformCampusKey?: string | null;
	applicationId: string;
}) {
	const [deleteContainer] = useDeleteContainerMutation({
		update(cache, { data }) {
			cache.evict({
				id: cache.identify({
					__typename: 'ChildContainer',
					id: data?.deleteContainer.deletedContainer.id,
				}),
			});
			cache.gc();
		},
	});

	const [deleteItem] = useDeleteItemMutation({
		update(cache, { data }) {
			cache.evict({
				id: cache.identify({
					__typename: 'ChildItem',
					id: data?.deleteItem.deletedItem.id,
				}),
			});
			cache.gc();
		},
	});

	const [getContainerSettings] = useGetContainerSettingsLazyQuery({ fetchPolicy: 'network-only' });

	const generateDeleteHandler =
		({ id, type, parentId, onCompleted: onCompletedHandler }: DeleteHandlerType) =>
		() => {
			switch (type) {
				case ContainerCardType.Item: {
					if (parentId) {
						deleteItem({
							variables: {
								organizationKey,
								platformCampusKey,
								deleteItemInput: {
									applicationId,
									itemId: id,
									parentContainerId: parentId,
								},
							},
							optimisticResponse: {
								deleteItem: {
									deletedItem: {
										id,
									},
								},
							},
							onCompleted: onCompletedHandler,
						});
					}
					break;
				}
				case ContainerCardType.CCB:
				case ContainerCardType.Container:
				case ContainerCardType.StudioC:
				case ContainerCardType.Resi: {
					deleteContainer({
						variables: {
							organizationKey,
							platformCampusKey,
							deleteContainerInput: {
								applicationId,
								containerId: id,
							},
						},
						optimisticResponse: {
							deleteContainer: {
								deletedContainer: {
									id,
								},
							},
						},
						onCompleted() {
							onCompletedHandler?.();

							if (parentId) {
								getContainerSettings({
									variables: {
										organizationKey,
										platformCampusKey,
										applicationId,
										containerId: parentId,
										paging: {
											size: CONTAINER_CHILDREN_PAGING_SIZE,
										},
									},
								});
							}
						},
					});
					break;
				}
				default:
					assertNever(type);
			}
		};
	return { generateDeleteHandler };
}
