import { useEffect, useMemo, useState } from 'react';

import { TStudioCCard } from '@pushpay/app-components/dist/app/components/card/StudioCCard/StudioCCard';
import { StudioCCardType } from '@pushpay/app-components/dist/app/constants';
import { StudioCScreen } from '@pushpay/app-components/dist/app/screens/StudioCScreen';
import { messageFormatter } from '@pushpay/app-components/dist/app/services/studioc';
import { useAuthenticated } from '@pushpay/auth';
import { useIsMounted } from '@pushpay/utils';

import { ImageFormatUrls, StudioCContainerTypeSettings } from '@src/graphql/generated';
import { useMyApp } from '@src/myContext';
import { send } from '@src/raygun';
import { getAppSettings, useEvent } from '@src/utils';

import { getImageMap } from '../utils';

const STUDIOC_DASHBOARD_CARD_ID = 'studioc-dashboard-card';

export function StudioCContainerPreview({
	typeSpecificSettings,
	containerId,
	images,
}: {
	typeSpecificSettings: StudioCContainerTypeSettings;
	containerId: string;
	images?: ImageFormatUrls | null;
}) {
	const { currentApp } = useMyApp();
	const { mobileApiUrl } = getAppSettings();
	const { id: applicationId } = currentApp;
	const dashBoardCardImageUrl = getImageMap(images)?.lmw;
	const isMounted = useIsMounted();
	const dashboardCard = useMemo(
		() => ({
			...typeSpecificSettings,
			imageUrl: dashBoardCardImageUrl,
			id: STUDIOC_DASHBOARD_CARD_ID,
			type: StudioCCardType.Dashboard,
		}),
		[dashBoardCardImageUrl, typeSpecificSettings]
	);
	const [cards, setCards] = useState<TStudioCCard[]>([dashboardCard]);

	const { getToken } = useAuthenticated();

	const handleStudioCLinkChange = useEvent(async () => {
		const token = await getToken();
		const anonymousMessagesApiPath = `${mobileApiUrl}/mobile/application/${applicationId}/integrations/studioc/container/${containerId}/anonymous_messages`;

		const response = await fetch(anonymousMessagesApiPath, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
		});

		if (!response.ok) {
			const error = await response.text();
			send(new Error(`Failed to fetch anonymous messages for StudioC container ${containerId}`), [{ error }]);

			return;
		}

		if (!isMounted()) return;

		const anonymousCards = await response.json();
		const formattedCards = messageFormatter(anonymousCards);

		setCards([dashboardCard, ...formattedCards]);
	});

	useEffect(() => {
		if (!typeSpecificSettings.link) return;

		handleStudioCLinkChange();
	}, [typeSpecificSettings.link, handleStudioCLinkChange]);

	return <StudioCScreen cards={cards} dashBoardCtaUrl={typeSpecificSettings.link} scrollRef={undefined as any} />;
}
