import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useRef } from 'react';

import { CardProps } from '@src/components/card';
import { DraggableItemData, DraggableContainerData, useDraggablesDefinition } from '@src/components/draggable';
import { SelectedContainer } from '@src/components/modals/DestinationModal';
import { MoveChildInput, ContainerType, ItemType } from '@src/graphql/generated';
import { useOptimizedCopyItemsMutation, useOptimizedMoveChildrenMutation } from '@src/pages/appDesign/hooks';
import { loader as containerChildrenLoader } from '@src/pages/containerChildren';
import { useGetQueryAndMutationVars, useMapState, MapState } from '@src/shared/hooks';
import { ContainerCardType } from '@src/utils/cardType';

declare const mapState: MapState<CardProps>;

export type CopyAndMoveStateContextType = {
	itemsMap: Map<string, CardProps>;
};

type CopyItemHandler = {
	card: CardProps;
	containerId: string;
};

type CopyItemsHandler = {
	items: CardProps[];
	containerId: string;
};

type MoveChildrenHandlerType = {
	parentContainerId: string;
	newParentContainer: SelectedContainer;
	selectedChildren: MoveChildInput[];
};

type PairType = { type: ContainerCardType; containerType?: ContainerType; itemType?: ItemType };

type MoveChildType = MoveChildInput & PairType;

type CopyAndMoveDispatchContextType = {
	selectItem: typeof mapState.addItem;
	unselectItem: typeof mapState.deleteItem;
	hasItemWithId: typeof mapState.hasItemWithId;
	getItemCount: typeof mapState.getItemCount;
	getSelectedItems: typeof mapState.getAllItems;
	clear: typeof mapState.clear;
	hasContainerInList: () => boolean;
	hasRestrictedRootTypes: () => boolean;
	copyItemHandler: ({ card, containerId }: CopyItemHandler) => () => void;
	copyItemsHandler: ({ items, containerId }: CopyItemsHandler) => void;
	moveChildrenHandler: ({ parentContainerId, newParentContainer, selectedChildren }: MoveChildrenHandlerType) => void;
	hasCompatibleCards: (containerType: ContainerType, selectedCards: CardProps[]) => boolean;
};

export const CopyAndMoveStateContext = createContext<CopyAndMoveStateContextType | null>(null);
CopyAndMoveStateContext.displayName = 'CopyAndMoveStateContext';

export const CopyAndMoveDispatchContext = createContext<CopyAndMoveDispatchContextType | null>(null);
CopyAndMoveDispatchContext.displayName = 'CopyAndMoveDispatchContext';

const FEED_ITEM_TYPES = [ItemType.News, ItemType.Video, ItemType.Podcast] as const;

function CopyAndMoveContextProvider({ children }: PropsWithChildren) {
	const {
		itemsMap,
		addItem,
		deleteItem: unselectItem,
		hasItemWithId,
		getItemCount,
		getAllItems,
		clear,
	} = useMapState<CardProps>();

	const selectedChildRef = useRef<MoveChildType | null>(null);
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const { copyItems } = useOptimizedCopyItemsMutation({
		organizationKey,
		platformCampusKey,
		applicationId,
	});

	const { moveChildren } = useOptimizedMoveChildrenMutation({
		organizationKey,
		platformCampusKey,
		applicationId,
	});

	const { getContainerChildrenDraggables } = useDraggablesDefinition();

	const getContainerChildrenTypes = useCallback(
		(containerType: ContainerType) => [
			...getContainerChildrenDraggables(containerType).map(
				draggable => (draggable?.actions[0]?.data as DraggableContainerData | DraggableItemData)?.type
			),
			...(containerType === ContainerType.Default ? FEED_ITEM_TYPES : []),
		],
		[getContainerChildrenDraggables]
	);

	const rootContainerTypes = getContainerChildrenTypes(ContainerType.Root);

	const isRestrictedRootType = useCallback(
		({ type, containerType }: PairType) =>
			type === 'item' || ((containerType && !rootContainerTypes.includes(containerType)) ?? false),
		[rootContainerTypes]
	);

	const hasRestrictedRootTypes = useCallback(() => {
		const selectedCards = getAllItems();
		if (selectedCards.length) {
			return selectedCards.some(isRestrictedRootType);
		}

		if (selectedChildRef.current) {
			return isRestrictedRootType(selectedChildRef.current);
		}

		return false;
	}, [getAllItems, isRestrictedRootType]);

	const isCardCompatible = useCallback(
		(types: (ContainerType | ItemType)[], { containerType, itemType }: PairType): boolean =>
			!!((itemType && types.includes(itemType)) || (containerType && types.includes(containerType))),
		[]
	);

	const hasCompatibleCards = useCallback(
		(containerType: ContainerType, selectedCards: CardProps[]) => {
			const compatibleTypes = getContainerChildrenTypes(containerType);
			const hasCompatibleTypes = compatibleTypes.length > 0;

			if (!hasCompatibleTypes) {
				return false;
			}
			if (selectedCards.length) {
				return selectedCards.every(card => isCardCompatible(compatibleTypes, card));
			}
			if (selectedChildRef.current) {
				const selectedCard = selectedChildRef.current;
				return isCardCompatible(compatibleTypes, selectedCard);
			}
			return false;
		},
		[isCardCompatible, getContainerChildrenTypes]
	);

	const hasContainerInList = useCallback(() => !getAllItems().every(item => item.type === 'item'), [getAllItems]);

	const copyItemHandler = useCallback(
		({ card, containerId }: CopyItemHandler) =>
			() => {
				copyItems({
					parentContainerId: containerId,
					items: [card],
				});
			},
		[copyItems]
	);

	const copyItemsHandler = useCallback(
		({ items, containerId }: CopyItemsHandler) => {
			copyItems({
				parentContainerId: containerId,
				items,
			});
			clear();
		},
		[clear, copyItems]
	);

	const moveChildrenHandler = useCallback(
		({ parentContainerId, newParentContainer, selectedChildren }: MoveChildrenHandlerType) => {
			moveChildren({ parentContainerId, newParentContainer, children: selectedChildren });
			clear();
		},
		[clear, moveChildren]
	);

	const copyAndMoveStateContextValue = useMemo(() => ({ itemsMap }), [itemsMap]);

	const selectItem = useCallback(
		async (item: CardProps) => {
			if (item.containerType) {
				await containerChildrenLoader({
					organizationKey,
					platformCampusKey,
					applicationId,
					containerId: item.id,
				});
			}

			addItem(item);
		},
		[addItem, organizationKey, platformCampusKey, applicationId]
	);

	const copyAndMoveDispatchContextValue = useMemo(
		() => ({
			selectItem,
			unselectItem,
			hasItemWithId,
			hasContainerInList,
			hasRestrictedRootTypes,
			getItemCount,
			getSelectedItems: getAllItems,
			clear,
			copyItemHandler,
			copyItemsHandler,
			moveChildrenHandler,
			hasCompatibleCards,
		}),
		[
			selectItem,
			unselectItem,
			hasItemWithId,
			hasContainerInList,
			hasRestrictedRootTypes,
			getItemCount,
			getAllItems,
			clear,
			copyItemHandler,
			copyItemsHandler,
			moveChildrenHandler,
			hasCompatibleCards,
		]
	);

	return (
		<CopyAndMoveStateContext.Provider value={copyAndMoveStateContextValue}>
			<CopyAndMoveDispatchContext.Provider value={copyAndMoveDispatchContextValue}>
				{children}
			</CopyAndMoveDispatchContext.Provider>
		</CopyAndMoveStateContext.Provider>
	);
}

function useCopyAndMoveStateContext() {
	const context = useContext(CopyAndMoveStateContext);
	if (!context) {
		throw new Error('useCopyAndMoveStateContext must be used within a CopyAndMoveContextProvider');
	}
	return context;
}

function useCopyAndMoveDispatchContext() {
	const context = useContext(CopyAndMoveDispatchContext);
	if (!context) {
		throw new Error('useCopyAndMoveDispatchContext must be used within a CopyAndMoveContextProvider');
	}
	return context;
}

export { useCopyAndMoveStateContext, CopyAndMoveContextProvider, useCopyAndMoveDispatchContext };
