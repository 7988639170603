import { ReactNode, useEffect, useState } from 'react';

import {
	TextField,
	TextAreaField,
	FormState,
	ValidationRule,
	RuleType,
	StringLengthValidationRule,
	Field,
} from '@pushpay/forms';
import { Stack, StackItem } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';
import { MemoizableFunction } from '@pushpay/utils';

import { UrlField } from '@src/components/formFields';
import { SaveContainerSettingsSchema } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n/translation';
import { useEvent } from '@src/utils';

const useStyles = createUseStyles((theme: Theme) => ({
	label: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
		color: theme.colors['text-default'],
	},
	title: {
		marginTop: theme.SPACING.MEDIUM,
	},
	description: {
		marginTop: 0,
		'& textarea': {
			resize: 'vertical',
		},
	},
	url: {
		paddingTop: theme.SPACING.SMALL,
	},
	cta: {
		'& input': {
			textTransform: 'uppercase',
		},
	},
	stackItem: {
		display: 'flex',
		gap: theme.SPACING.NONE,
		flexDirection: 'column',
	},
}));

export type StudioCFieldProps = ComponentProps<
	{
		fields: FormState<
			SaveContainerSettingsSchema['input']['typeSpecificSettings']['studioCContainerSettingsInput']
		>;
	},
	typeof useStyles
>;

const getStringLengthFromValidationRules = (validationRules: ValidationRule[]) => {
	const stringLengthRule = validationRules.find(rule => rule.type === RuleType.StringLength) as
		| StringLengthValidationRule
		| undefined;
	return stringLengthRule?.maximumLength;
};

const addRequiredRuleToField = <T = string,>(field: Field<T>): Field<T> => ({
	...field,
	validationRules: [{ type: RuleType.Required }, ...field.validationRules],
	serverError: undefined,
});

const StudioCServerError = {
	InvalidLink: 'Invalid StudioC link',
	InvalidLinkFormat: 'Invalid StudioC link format',
};

export function StudioCSection({ fields, classes: classesProp }: StudioCFieldProps) {
	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');
	const [invalidUrlError, setInvalidUrlError] = useState<string | MemoizableFunction<() => ReactNode>>();

	const handleStudioCLinkError = useEvent(() => {
		const studioCLinkServerError = fields.link.serverError;

		if (!studioCLinkServerError) {
			setInvalidUrlError(undefined);
			return;
		}

		if (studioCLinkServerError === StudioCServerError.InvalidLinkFormat) {
			const churchNameRegex = /^https?:\/\/(?<churchName>[^.]+)(.*)$/;
			const churchNameMatch = fields.link.value.match(churchNameRegex);

			const additionalInfo = churchNameMatch?.groups?.churchName
				? `Do you mean https://${churchNameMatch.groups.churchName}.thestudioc.org?`
				: 'Please follow the format: https://<church-name>.thestudioc.org';

			setInvalidUrlError(translate('settings.container.studioC.invalidUrlFormat', { additionalInfo }));
			return;
		}

		const studioCUrlErroWithHelpLink = (
			<>
				{translate('settings.container.studioC.invalidUrl')}
				&nbsp;
				<a href="https://studiochelp.zendesk.com/hc/en-us" rel="noopener noreferrer" target="_blank">
					{translate('settings.container.studioC.studioCHelp')}
				</a>
				.
			</>
		);

		const invalidUrlWithStudioCHelpLink = {
			deps: [studioCUrlErroWithHelpLink],
			fn: () => studioCUrlErroWithHelpLink,
		};

		setInvalidUrlError(invalidUrlWithStudioCHelpLink);
	});

	useEffect(() => {
		handleStudioCLinkError();
	}, [fields.link, handleStudioCLinkError]);

	return (
		<Stack space="LARGE">
			<StackItem className={classes.stackItem}>
				<UrlField
					classes={{ label: classes.label, root: classes.url }}
					customValidationFailureMessage={invalidUrlError}
					field={addRequiredRuleToField(fields.link)}
					label={translate('settings.container.studioC.link')}
					labelPosition="top"
					placeholder={translate('settings.container.studioC.linkPlaceholder')}
					tooltip={translate('settings.container.studioC.linkInformation')}
					showLabel
				/>
				<TextField
					characterCounter={{ label: '' }}
					classes={{ label: classes.label, root: classes.title }}
					field={addRequiredRuleToField(fields.title)}
					label={translate('settings.container.studioC.title')}
					labelPosition="top"
					textInputProps={{
						maxLength: getStringLengthFromValidationRules(fields.title.validationRules),
					}}
					showLabel
				/>
				<TextAreaField
					characterCounter={{ label: '' }}
					classes={{
						label: classes.label,
						root: classes.description,
					}}
					field={fields.description}
					label={translate('settings.container.studioC.description')}
					labelPosition="top"
					rows={2}
					textAreaProps={{
						maxLength: getStringLengthFromValidationRules(fields.description.validationRules),
					}}
					showLabel
				/>
				<TextField
					characterCounter={{ label: '' }}
					classes={{
						label: classes.label,
						input: classes.cta,
					}}
					field={addRequiredRuleToField(fields.ctaLabel)}
					label={translate('settings.container.studioC.ctaLabel')}
					labelPosition="top"
					textInputProps={{
						maxLength: getStringLengthFromValidationRules(fields.ctaLabel.validationRules),
					}}
					showLabel
				/>
			</StackItem>
		</Stack>
	);
}
