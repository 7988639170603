import { InfoIcon } from '@pushpay/iconography';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { useFeature } from '@src/featureFlags';
import { useTranslation } from '@src/i18n';
import { ContainerCardType } from '@src/utils/cardType';

import { TagWithTooltip } from '../tag';

type Props = {
	targetId?: string;
	hasNoTimeframeProperty: boolean;
	hasInvalidTimeframeProperties: boolean;
	isHiddenTooltipDisplayed: boolean;
	type: ContainerCardType;
};

const useStyles = createUseStyles((theme: Theme) => ({
	tagIcon: {
		marginInlineEnd: theme.SPACING.XXSMALL,
	},
}));

export const CardHiddenTag = ({
	targetId,
	hasInvalidTimeframeProperties,
	hasNoTimeframeProperty,
	isHiddenTooltipDisplayed,
	type,
}: Props) => {
	const classes = useStyles();
	const { translate } = useTranslation('appDesign');
	const isStudioCIntegrationEnabled = useFeature('StudioCIntegration');

	const getTooltipLabel = () => {
		if (isHiddenTooltipDisplayed) return undefined;

		if (hasInvalidTimeframeProperties) return translate('tag.tooltip.invalidTimeframeProperty');

		if (hasNoTimeframeProperty) return translate('tag.tooltip.noTimeframeProperty');

		if (isStudioCIntegrationEnabled && type === ContainerCardType.StudioC) {
			return translate('tag.tooltip.noStudioCUrl');
		}

		return undefined;
	};

	const tooltipLabel = getTooltipLabel();
	const tooltipIcon = tooltipLabel
		? () => <InfoIcon classes={{ root: classes.tagIcon }} displaySize="medium" />
		: undefined;

	return (
		<TagWithTooltip
			data-pp-at-target={targetId}
			displayStyle="default"
			icon={tooltipIcon}
			label={translate('tag.hidden')}
			tooltipLabel={tooltipLabel}
		/>
	);
};
