/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-shadow */
import { DraggableContainerData } from '@src/components/draggable/types';
import { useDraggablesDataContext } from '@src/context/draggableData/draggablesDataContext';
import { useFeature } from '@src/featureFlags';
import { ContainerType } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { getParentIdAndInsertBeforeId, isDraggableType } from '@src/pages/appDesign/helper';
import { DropHandler } from '@src/pages/appDesign/type';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';

import { useOptimizedCreateContainerMutation } from '../useOptimizedCreateContainerMutation';

export function useCreatingContainerHandler() {
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const { drop } = useDraggablesDataContext();
	const { translate } = useTranslation('appDesign');
	const createContainer = useOptimizedCreateContainerMutation();
	const isStudioCIntegrationEnabled = useFeature('StudioCIntegration');

	const onCreatingContainerDropHandler: DropHandler = (event, draggingItem) => {
		const { over } = event;
		if (!over) {
			return;
		}
		const { parentId, insertBeforeId } = getParentIdAndInsertBeforeId(over);

		if (isDraggableType(draggingItem)) {
			if (draggingItem.type === 'container') {
				drop(draggingItem.name);
				handleCreatingContainer(parentId, insertBeforeId);
			}
		}

		function handleCreatingContainer(parentId: string, insertBeforeId: string | null) {
			if (draggingItem && !isDraggableType(draggingItem)) {
				return;
			}
			const createContainerAction = draggingItem?.actions.find(
				({ action_type, model }) => action_type === 'create' && model === 'container'
			);
			const createContainerActionData = createContainerAction?.data as DraggableContainerData;

			const createContainerParams: Parameters<typeof createContainer>[0] = {
				organizationKey,
				platformCampusKey,
				input: {
					applicationId,
					parentContainerId: parentId,
					name: createContainerActionData.name,
					icon: draggingItem?.icon,
					template: createContainerActionData.template,
					type: createContainerActionData.type,
					insertBefore: insertBeforeId,
					relatedContent: {
						isEnabled: createContainerActionData.relatedContentOn ?? true,
						title: translate('defaultValue.defaultRelatedContentTitle'),
					},
				},
			};

			if (isStudioCIntegrationEnabled && createContainerActionData.type === ContainerType.StudioC) {
				const { typeSpecificSettings } = createContainerActionData;

				createContainerParams.input.typeSpecificSettings = {
					studioCContainerSettingsInput: typeSpecificSettings,
				};

				createContainerParams.input.isHidden = !typeSpecificSettings?.link;
			}

			createContainer(createContainerParams);
		}
	};

	return onCreatingContainerDropHandler;
}
