import { GraphQLError } from 'graphql';

import { createErrorLink } from '@pushpay/apollo-links';

import { send } from '@src/raygun';
import { useServerErrorNotification } from '@src/shared/hooks';

const STUDIO_C_UNREACHABLE_ERROR_CODE = 'STUDIO_C_SERVICE_UNREACHABLE';

export function useApolloClientErrorHandlers() {
	const { showGenericNetworkAndServerError, showStudioCServiceUnreachableError } = useServerErrorNotification();
	const handleNetworkError = (errorMessage: string, error: Error) => {
		// page reload will redirect user to login page
		if (errorMessage.includes('Login required')) {
			window.location.reload();
			return;
		}
		send(error);
		if (errorMessage.includes('STUDIO_C_SERVICE_UNREACHABLE')) {
			return;
		}
		showGenericNetworkAndServerError();
		// eslint-disable-next-line no-console
		console.error(errorMessage);
	};

	const handleGraphQLErrors = (errorMessage: string, error: GraphQLError) => {
		// eslint-disable-next-line no-console
		console.error(errorMessage);
		if (error.extensions?.remote?.extensions?.code === STUDIO_C_UNREACHABLE_ERROR_CODE) {
			showStudioCServiceUnreachableError();
			return;
		}
		// better to check extension code, but BFF API doesn't forward it so far.
		if (errorMessage.includes('Internal Server Error')) {
			showGenericNetworkAndServerError();
		}
	};
	return createErrorLink({ handleGraphQLErrors, handleNetworkError });
}
