/*
 * This file is automatically generated.
 * Do not add changes in here.
 */

import * as React from 'react';

import { Moment } from 'moment';

import { useTranslationI18next, TransI18next, TransPropsI18next, UseTranslationOptions } from '@pushpay/i18n';

export type TranslationLanguage = {
	common: {
		hi: never;

		welcome: never;

		loading: never;

		loadingPreview: never;

		'errors.title.error': never;

		'errors.title.generalError': never;

		'errors.title.accessDeniedError': never;

		'errors.title.noApplicationError': never;

		'errors.title.pageNotFoundError': never;

		'errors.text.generalError': never;

		'errors.text.noAppStudioAccessError': never;

		'errors.text.noOrgAccessError': never;

		'errors.text.noApplicationError': never;

		'errors.text.networkOrServerError': never;

		'errors.text.deleteContainerError': never;

		'errors.text.pageNotFoundError': never;

		'errors.text.fileUploadError': {
			file: string | number;
		};

		'errors.text.fileSizeError': {
			fileSize: string | number;
		};

		'errors.text.fileTypeUnsupported': {
			acceptedFileTypes: string | number;
		};

		'errors.text.misConfigQrCodeError': never;

		'errors.text.qrCodeGenerationError': never;

		'errors.CTA.getHelp': never;

		'errors.CTA.reloadPage': never;

		'errors.CTA.goBack': never;

		'errors.propertiesFormatError': never;

		'organizationSwitcher.clearSearch': never;

		'organizationSwitcher.searchPlaceholder': never;

		'organizationSwitcher.selectOrganization': never;

		'organizationSwitcher.switchOrganization': never;

		'productSwitcher.title': never;

		'productSwitcher.buttonLabel': never;

		'productSwitcher.marketing.label': never;

		'productSwitcher.marketing.title': never;

		'productSwitcher.appStudio.label': never;

		'productSwitcher.appStudio.description': never;

		'productSwitcher.chms.label': never;

		'productSwitcher.chms.learnAbout': never;

		'productSwitcher.chms.description': never;

		'productSwitcher.giving.label': never;

		'productSwitcher.giving.learnAbout': never;

		'productSwitcher.giving.description': never;

		'productSwitcher.insights.label': never;

		'productSwitcher.insights.learnAbout': never;

		'productSwitcher.insights.description': never;

		'productSwitcher.noOrgAccessTitle': never;

		'productSwitcher.tooltips.noPermission': never;

		'productSwitcher.tooltips.organizationNoProduct': never;

		'productSwitcher.learnAbout': never;

		'profileMenu.profileLabel': never;

		'profileMenu.personalAccountLabel': never;

		'profileMenu.logoutLabel': never;

		'profileMenu.loggingOutLabel': never;

		'profileMenu.profileButtonAriaLabel': never;

		downloadLink: never;

		'sideMenu.expandMenu': never;

		'sideMenu.collapseMenu': never;

		'sideMenu.tooltips.appDesign': never;

		'sideMenu.tooltips.pushNotifications': never;

		'sideMenu.tooltips.analytics': never;

		'sideMenu.tooltips.bridge': never;

		'sideMenu.tooltips.privacyPolicy': never;

		'sideMenu.tooltips.termsAndConditions': never;

		'layout.mobile.title': never;

		'layout.mobile.text': never;

		'modal.close': never;

		'copyAndMove.copyName': {
			name: string | number;
		};

		'copyAndMove.errors.copyItem': {
			itemName: string | number;
		};

		'copyAndMove.errors.copyItems': never;

		'copyAndMove.errors.copyRootContainer': never;

		'copyAndMove.errors.copyContainer': never;

		'copyAndMove.errors.moveRootContainer': never;

		'copyAndMove.errors.moveChild': never;

		'copyAndMove.errors.moveChildren': never;

		'copyAndMove.errors.rootChildMoveTooltip': {
			draggableName: string | number;
		};
	};

	appDesign: {
		'imageUploader.label': never;

		'imageUploader.heroBannerLabel': never;

		'imageUploader.title': never;

		'imageUploader.subTitle': never;

		'imageUploader.button': never;

		'imageUploader.buttonTooltip': never;

		'imageUploader.subButton': never;

		'imageUploader.urlPlaceholder': never;

		'imageUploader.invalidUrl': never;

		'imageUploader.applyButton': never;

		'imageUploader.removeImageButton': never;

		'imageUploader.noteTitle': never;

		'imageUploader.noteContent': never;

		'imageUploader.preview.label': never;

		'imageUploader.preview.recommended': never;

		'imageUploader.preview.square': never;

		'imageUploader.preview.squareTooltip': never;

		'imageUploader.preview.16x9': never;

		'imageUploader.preview.16x9Tooltip': never;

		'imageUploader.preview.panorama': never;

		'imageUploader.preview.panoramaTooltip': never;

		'imageUploader.imageUploadTooltip': {
			acceptedFileTypes: string | number;
		};

		'kebabMenuItems.edit': {
			type: string | number;
		};

		'kebabMenuItems.show': {
			type: string | number;
		};

		'kebabMenuItems.hide': {
			type: string | number;
		};

		'kebabMenuItems.delete': never;

		'kebabMenuItems.copy': never;

		'kebabMenuItems.move': never;

		'kebabMenuItems.comingSoon': never;

		'kebabMenuItems.feedDeleteDisabledTooltip': never;

		'kebabMenuItems.subContainerDeleteDisabledTooltip': never;

		'snackBarItems.selected': {
			totalSelected: string | number;
			maxItemsToSelect: string | number;
		};

		'snackBarItems.copy': never;

		'snackBarItems.move': never;

		'snackBarItems.delete': never;

		'moveSuccessBanner.title': never;

		'moveSuccessBanner.text': never;

		'deleteModal.defaultDeleteText': never;

		'deleteModal.containerCardDeleteText': never;

		'deleteModal.itemCardDeleteText': never;

		'deleteModal.confirm': never;

		'deleteModal.cancel': never;

		'confirmationDialog.modalTitle': never;

		'confirmationDialog.modalText': never;

		'confirmationDialog.confirm': never;

		'confirmationDialog.cancel': never;

		'confirmationDialog.discard': never;

		'publishConfirmationDialog.modalTitle': never;

		'publishConfirmationDialog.modalText': never;

		'publishConfirmationDialog.confirm': never;

		'publishConfirmationDialog.cancel': never;

		'discardDialog.modalTitle': never;

		'discardDialog.modalText': never;

		'discardDialog.cancel': never;

		'selectCardConfirmationDialog.modalTitle': never;

		'selectCardConfirmationDialog.modalText': never;

		'selectCardConfirmationDialog.confirm': never;

		'selectCardConfirmationDialog.cancel': never;

		'cardType.container': never;

		'cardType.item': never;

		'cardType.ccbModule': never;

		'cardType.feedItem': never;

		'preview.darkModeToggle.label': never;

		'preview.darkModeToggle.unavailable': never;

		'preview.ccbContainerPlaceholder': never;

		'preview.unsupportedContainerPlaceholder': never;

		'preview.favoriteContainerPlaceholder': never;

		'preview.webviewPlaceholder': never;

		'preview.shellUpdateInfo': never;

		'preview.outdatedShellBanner': never;

		'actions.saveButton.text.idle': never;

		'actions.saveButton.text.saving': never;

		'actions.saveButton.text.success': never;

		'actions.discard.text.idle': never;

		'actions.publish.text.idle': {
			pendingCount: string | number;
		};

		'actions.publish.text.saving': never;

		'actions.publish.text.success': never;

		'draggables.title': never;

		'draggables.hint': never;

		'draggables.dhsHint': never;

		'draggables.ccbDraggables': never;

		'draggables.ccbAbbr': never;

		'draggables.resiDraggables': never;

		'draggables.emptyDraggables': never;

		'draggables.youTubeDraggable': never;

		'draggables.vimeoDraggable': never;

		'draggables.resiDraggable': never;

		'draggables.resiDraggableLabel': never;

		'draggables.campusDraggable': never;

		'draggables.studioCDraggable': never;

		'draggables.integrations': never;

		'draggables.disabledEventContainerDraggableTooltip': never;

		'draggables.disabledCampusDraggableTooltip': never;

		'draggables.disabledFeedDraggableTooltip': never;

		'draggables.disabledItemDraggableTooltip': never;

		'draggables.disabledPropertyDraggableTooltip': never;

		'draggables.disabledChMSDraggableTooltip': never;

		'draggables.unsupportedResiDraggableTooltip': never;

		'draggables.outdatedShellVersionDraggableTooltip': {
			draggableName: string | number;
		};

		'searchInput.placeholderText': never;

		'searchInput.resetButtonAriaLabel': never;

		'searchInput.searchButtonAriaLabel': never;

		'iconPicker.title': never;

		'iconPicker.cancel': never;

		'iconPicker.select': never;

		'breadcrumb.content': never;

		'breadcrumb.DHS': never;

		'breadcrumb.containerSettings': {
			containerName: string | number;
		};

		'content.title': never;

		'content.appContent': never;

		'dhs.title': never;

		'settings.featured': never;

		'settings.featuredTooltip': never;

		'settings.title': never;

		'settings.container.name': never;

		'settings.container.type': never;

		'settings.container.typeTooltip': never;

		'settings.container.icon': never;

		'settings.container.iconTooltip': never;

		'settings.container.disabledEventTitleBarTooltip': never;

		'settings.container.disabledIconTooltip': never;

		'settings.container.givingLink': never;

		'settings.container.givingLinkPlaceholder': never;

		'settings.container.givingLinkTooltip': never;

		'settings.container.customGivingLink': never;

		'settings.container.campusGivingLink': never;

		'settings.container.applicationGivingLink': never;

		'settings.container.subTitle': never;

		'settings.container.subTitleOptions.noAction': never;

		'settings.container.noAvailableOptions': never;

		'settings.container.titlebar': never;

		'settings.container.disabledLabelForYouTube': never;

		'settings.container.resiFeed': never;

		'settings.container.studioC.link': never;

		'settings.container.studioC.linkPlaceholder': never;

		'settings.container.studioC.title': never;

		'settings.container.studioC.description': never;

		'settings.container.studioC.ctaLabel': never;

		'settings.container.studioC.linkInformation': never;

		'settings.container.studioC.connectionSucceeded': never;

		'settings.container.studioC.connectionFailed': never;

		'settings.container.studioC.passwordInformation': never;

		'settings.container.studioC.username': never;

		'settings.container.studioC.password': never;

		'settings.container.studioC.passwordIsStored': never;

		'settings.container.studioC.updatePassword': never;

		'settings.container.studioC.cancelUpdate': never;

		'settings.container.studioC.invalidUrl': never;

		'settings.container.studioC.studioCFailedErrorTitle': never;

		'settings.container.studioC.studioCFailedErrorMessage': never;

		'settings.container.studioC.studioCContact': never;

		'settings.container.studioC.invalidUrlFormat': {
			additionalInfo: string | number;
		};

		'settings.container.studioC.studioCHelp': never;

		'settings.container.titlebarOptions.noAction': never;

		'settings.container.titlebarOptions.filter': never;

		'settings.container.titlebarOptions.map': never;

		'settings.container.titlebarOptions.search': never;

		'settings.container.relatedContent.title': never;

		'settings.container.relatedContent.titleToolTip': never;

		'settings.container.relatedContent.toggle': never;

		'settings.container.relatedContent.selectLabel': never;

		'settings.container.relatedContent.alsoLike': never;

		'settings.container.relatedContent.inThisSeries': never;

		'settings.container.relatedContent.recommended': never;

		'settings.container.relatedContent.inThisPlaylist': never;

		'settings.container.relatedContent.latest': never;

		'settings.container.relatedContent.custom': never;

		'settings.container.relatedContent.customInputLabel': never;

		'settings.container.relatedContent.customInputPlaceholder': never;

		'settings.container.dhs.title': never;

		'settings.container.dhs.toggle': never;

		'settings.container.dhs.titleTooltip': never;

		'settings.container.dhs.cardTypeTooltip': never;

		'settings.container.dhs.maxLimitTooltip': {
			maxDHSCardLimit: string | number;
		};

		'settings.container.dhs.dataMapping.subTitle': never;

		'settings.container.dhs.dataMapping.summary': never;

		'settings.container.dhs.dataMapping.publishedTime': never;

		'settings.container.dhs.dataMapping.audio': never;

		'settings.container.dhs.dataMapping.video': never;

		'settings.container.dhs.dataMapping.label': never;

		'settings.container.dhs.dataMapping.toolTip': never;

		'settings.container.dhs.dataMapping.homeCardDetail': never;

		'settings.container.dhs.dataMapping.feedLabel': never;

		'settings.container.dhs.dataMapping.exampleData': never;

		'settings.container.dhs.dataMapping.feedLabelNotLinked': never;

		'settings.layout.checkbox': {
			type: string | number;
		};

		'settings.layout.Distance': never;

		'settings.layout.Icon': never;

		'settings.layout.Overlay': never;

		'settings.layout.Label': never;

		'settings.layout.title': never;

		'settings.layout.textLayoutLabel': never;

		'settings.layout.squareLayoutLabel': never;

		'settings.layout.rectangularLayoutLabel': never;

		'settings.layout.bannerLayoutLabel': never;

		'settings.layout.detailSquareLayoutLabel': never;

		'settings.layout.fullLayoutLabel': never;

		'settings.layout.webviewLayoutLabel': never;

		'itemSetting.itemNameLabel': never;

		'itemSetting.itemActions': never;

		'itemSetting.itemActionsTooltip': never;

		'itemSetting.itemActionPlaceHolder': never;

		'itemSetting.itemActionHideProperty': never;

		'itemSetting.dhsSetting.dhsTitle': never;

		'itemSetting.dhsSetting.dhsExpiredTooltip.title': never;

		'itemSetting.dhsSetting.dhsExpiredTooltip.content': never;

		'itemSetting.dhsSetting.dhsToggleName': never;

		'itemSetting.dhsSetting.dhsTooltip': never;

		'itemSetting.dhsSetting.dhsCardTypeLabel': never;

		'itemSetting.dhsSetting.dhsCardMaxItems': never;

		'itemSetting.dhsSetting.dhsCardTypeLabelTooltip': never;

		'itemSetting.dhsSetting.dhsImpactCardTypeLabelTooltip': never;

		'itemSetting.dhsSetting.dhsSubTitleLabel': never;

		'itemSetting.dhsSetting.dhsSummaryLabel': never;

		'itemSetting.dhsSetting.dhsShowPublishedTime': never;

		'itemSetting.dhsSetting.dhsShowCategory': never;

		'itemSetting.dhsSetting.dhsShowKeyMetrics': never;

		'itemSetting.dhsSetting.dhsShowTitle': never;

		'itemSetting.dhsSetting.dhsCTALabel': never;

		'itemSetting.dhsSetting.featuredLabel': never;

		'itemSetting.dhsSetting.subTitlePlaceholder': never;

		'itemSetting.dhsSetting.summaryPlaceholder': never;

		'itemSetting.dhsSetting.ctaOptions.readMore': never;

		'itemSetting.dhsSetting.ctaOptions.learnMore': never;

		'itemSetting.dhsSetting.ctaOptions.seeCampaign': never;

		'itemSetting.dhsSetting.ctaOptions.seeDetails': never;

		'itemSetting.dhsSetting.ctaOptions.getInvolved': never;

		'itemSetting.dhsSetting.ctaOptions.none': never;

		'itemSetting.dhsSetting.featuredLabelOptions.noLabel': never;

		'itemSetting.dhsSetting.featuredLabelOptions.featured': never;

		'itemSetting.dhsSetting.featuredLabelOptions.recommended': never;

		'itemSetting.dhsSetting.featuredLabelOptions.special': never;

		'itemSetting.dhsSetting.featuredLabelOptions.highlight': never;

		'itemSetting.dhsSetting.featuredLabelOptions.new': never;

		'itemSetting.dhsSetting.featuredLabelOptions.promo': never;

		'itemSetting.dhsSetting.featuredLabelOptions.spotlight': never;

		'itemSetting.inAppBrowser.label': never;

		'itemSetting.inAppBrowser.tooltip': never;

		'containerChildren.emptyIllustrationTitle': never;

		'containerChildren.emptyIllustrationText': never;

		'containerChildren.preConfiguredContainerTitle': never;

		'containerChildren.preConfiguredContainerText': never;

		'property.removeTooltip': never;

		'property.disableRemoveTooltip': never;

		'property.title': never;

		'property.dropArea': never;

		'property.collapseAll': never;

		'property.expandAll': never;

		'video.watch': never;

		'video.label': never;

		'video.labelPlaceholder': {
			type: string | number;
		};

		'video.url': never;

		'video.urlPlaceholder': {
			type: string | number;
		};

		'give.tooltip': never;

		'give.url': never;

		'give.urlPlaceholder': never;

		'addContact.description': never;

		'address.street': never;

		'address.city': never;

		'address.state': never;

		'address.zip': never;

		'address.latitude': never;

		'address.longitude': never;

		'address.geocode': never;

		'appLink.label': never;

		'appLink.labelPlaceholder': never;

		'appLink.androidAppUrl': never;

		'appLink.androidDefaultUrl': never;

		'appLink.iOSAppUrl': never;

		'appLink.iOSDefaultUrl': never;

		'audio.label': never;

		'audio.labelPlaceholder': never;

		'audio.url': never;

		'audio.urlPlaceholder': never;

		'share.shareLabel': never;

		'share.propertiesSelector.allPropertiesSelectedText': never;

		'share.propertiesSelector.somePropertiesSelectedText': never;

		'share.propertiesSelector.noPropertiesSelectedText': never;

		'share.propertiesSelector.applyButtonLabel': never;

		'share.propertiesSelector.selectAllActionText': never;

		'share.propertiesSelector.clearActionText': never;

		'share.selectedPropertiesLabel': never;

		'sms.label': never;

		'sms.phoneNumber': never;

		'sms.presetMessage': never;

		'text.description': never;

		'website.label': never;

		'website.url': never;

		'website.openExternally': never;

		'callToAction.label': never;

		'callToAction.url': never;

		'callToAction.openExternally': never;

		'callToAction.eventLabel': never;

		'userNote.label': never;

		'timeframe.startDate': never;

		'timeframe.startTime': never;

		'timeframe.endDate': never;

		'timeframe.endTime': never;

		'timeframe.allDayEvent': never;

		'carousel.label': never;

		'carousel.images': never;

		'carousel.removeImage': never;

		'keyMetrics.label': never;

		'keyMetrics.titleLabel': never;

		'keyMetrics.descriptionLabel': never;

		'keyMetrics.one': never;

		'keyMetrics.two': never;

		'keyMetrics.three': never;

		'advancedSettings.label': never;

		'advancedSettings.tooltip': never;

		'advancedSettings.hidePropertyRow': never;

		'advancedSettings.propertyIcon': never;

		'advancedSettings.propertyLabel': never;

		'attribute.campus.label': never;

		'attribute.youtube.label': never;

		'attribute.vimeo.label': never;

		'attribute.vimeo.learnMore': never;

		'attribute.vimeo.description': never;

		'attribute.vimeo.typeOptions.showcase': never;

		'attribute.vimeo.typeOptions.channel': never;

		'attribute.vimeo.typeOptions.group': never;

		'attribute.vimeo.typeOptions.userVideos': never;

		'attribute.vimeo.fieldLabels.showcaseId': never;

		'attribute.vimeo.fieldLabels.userId': never;

		'attribute.vimeo.fieldLabels.channelId': never;

		'attribute.vimeo.fieldLabels.groupId': never;

		'attribute.rss.label': never;

		'attribute.rss.type': never;

		'attribute.rss.newsFeed': never;

		'attribute.rss.videoFeed': never;

		'attribute.rss.podcastFeed': never;

		'attribute.ical.label': never;

		'attribute.video.fetchThumbnail': never;

		'attribute.video.fetchThumbnailDisabled': never;

		'attribute.url': never;

		'attribute.properties': never;

		'attribute.removeTooltip': never;

		'attribute.draggables': never;

		'attribute.dropArea': never;

		'attribute.processFeedButtonTooltip': never;

		'attribute.processFeedButtonAriaLabel': never;

		'attribute.processState.processing': never;

		'attribute.processState.complete': never;

		'attribute.processingCompleteMessage.title': never;

		'attribute.processingCompleteMessage.text': never;

		'attribute.processingFailedMessage.title': never;

		'attribute.processingFailedMessage.text': never;

		'attribute.processingFailedMessage.tag': never;

		'selectors.searchPlaceholder': never;

		'orgSwitcher.selectOrg': never;

		'defaultValue.defaultRelatedContentTitle': never;

		'defaultValue.containerDefinition.defaultContainerName': never;

		'defaultValue.containerDefinition.eventsContainerName': never;

		'defaultValue.containerDefinition.messageContainerName': never;

		'defaultValue.containerDefinition.giveContainerName': never;

		'defaultValue.containerDefinition.placesContainerName': never;

		'defaultValue.containerDefinition.campusContainerName': never;

		'defaultValue.containerDefinition.ccbCheckInContainer': never;

		'defaultValue.containerDefinition.ccbGroupsContainer': never;

		'defaultValue.containerDefinition.ccbServingContainer': never;

		'defaultValue.containerDefinition.ccbPublicNeeds': never;

		'defaultValue.containerDefinition.resiLibrary': never;

		'defaultValue.containerDefinition.studioC': never;

		'defaultValue.itemDefinition.defaultItemName': never;

		'defaultValue.itemDefinition.eventName': never;

		'defaultValue.itemDefinition.destinationName': never;

		'defaultValue.itemDefinition.contactName': never;

		'defaultValue.itemDefinition.mediaName': never;

		'defaultValue.itemDefinition.sermonName': never;

		'defaultValue.itemDefinition.webviewName': never;

		'defaultValue.itemDefinition.impactItem.name': never;

		'defaultValue.itemDefinition.impactItem.properties.websiteProperty.header': never;

		'defaultValue.propertyDefinition.timeFrameProperty.header': never;

		'defaultValue.propertyDefinition.addressProperty.header': never;

		'defaultValue.propertyDefinition.addToContactsProperty.header': never;

		'defaultValue.propertyDefinition.appLinkProperty.header': never;

		'defaultValue.propertyDefinition.audioProperty.header': never;

		'defaultValue.propertyDefinition.blankifyProperty.header': never;

		'defaultValue.propertyDefinition.callToActionProperty.header': never;

		'defaultValue.propertyDefinition.emailProperty.header': never;

		'defaultValue.propertyDefinition.facetimeProperty.header': never;

		'defaultValue.propertyDefinition.phoneProperty.header': never;

		'defaultValue.propertyDefinition.smsProperty.header': never;

		'defaultValue.propertyDefinition.textProperty.header': never;

		'defaultValue.propertyDefinition.userNoteProperty.header': never;

		'defaultValue.propertyDefinition.videoProperty.header': never;

		'defaultValue.propertyDefinition.resiProperty.header': never;

		'defaultValue.propertyDefinition.vimeoProperty.header': never;

		'defaultValue.propertyDefinition.shareProperty.header': never;

		'defaultValue.propertyDefinition.websiteProperty.header': never;

		'defaultValue.propertyDefinition.textHtmlProperty.header': never;

		'defaultValue.propertyDefinition.keyMetricsProperty.header': never;

		'defaultValue.propertyDefinition.carouselProperty.header': never;

		'defaultValue.propertyDefinition.giveProperty.header': never;

		'defaultValue.studioC.ctaLabel': never;

		'defaultValue.studioC.title': never;

		'defaultValue.studioC.description': never;

		'dragAndDrop.accessibility.screenReaderInstructions': never;

		'dragAndDrop.accessibility.announcements.onDragStart': {
			activeId: string | number;
		};

		'dragAndDrop.accessibility.announcements.onDragOverDroppable': {
			activeId: string | number;
			overId: string | number;
		};

		'dragAndDrop.accessibility.announcements.onDragOverNonDroppable': {
			activeId: string | number;
		};

		'dragAndDrop.accessibility.announcements.onDragEndOnDroppable': {
			activeId: string | number;
			overId: string | number;
		};

		'dragAndDrop.accessibility.announcements.onDragEndOnNonDroppable': {
			activeId: string | number;
		};

		'dragAndDrop.accessibility.announcements.onDragCancel': {
			activeId: string | number;
		};

		'richTextEditor.cancel': never;

		'richTextEditor.confirm': never;

		'richTextEditor.edit': never;

		'richTextEditor.remove': never;

		'richTextEditor.undo': never;

		'richTextEditor.redo': never;

		'richTextEditor.bold': never;

		'richTextEditor.boldAriaLabel': never;

		'richTextEditor.italic': never;

		'richTextEditor.italicAriaLabel': never;

		'richTextEditor.underline': never;

		'richTextEditor.underlineAriaLabel': never;

		'richTextEditor.strikethrough': never;

		'richTextEditor.strikethroughAriaLabel': never;

		'richTextEditor.outdent': never;

		'richTextEditor.indent': never;

		'richTextEditor.divider': never;

		'richTextEditor.insertLink': never;

		'richTextEditor.formattingOptions': never;

		'richTextEditor.bullet': never;

		'richTextEditor.h1': never;

		'richTextEditor.h2': never;

		'richTextEditor.h3': never;

		'richTextEditor.number': never;

		'richTextEditor.paragraph': never;

		'richTextEditor.url': never;

		'richTextEditor.openExternally': never;

		'richTextEditor.quote': never;

		'richTextEditor.blankify': never;

		'timepicker.am': never;

		'timepicker.pm': never;

		'timepicker.close': never;

		'destinationModal.move.title': never;

		'destinationModal.move.unselectedText': {
			selectionMessage: string | number;
		};

		'destinationModal.move.selectedText': {
			selectionMessage: string | number;
			destinationContainer: string | number;
		};

		'destinationModal.move.save': never;

		'destinationModal.move.restrictedTooltip': never;

		'destinationModal.copy.title': never;

		'destinationModal.copy.unselectedText': {
			selectionMessage: string | number;
		};

		'destinationModal.copy.selectedText': {
			selectionMessage: string | number;
			destinationContainer: string | number;
		};

		'destinationModal.copy.save': never;

		'destinationModal.copy.restrictedTooltip': never;

		'destinationModal.selection': {
			totalSelection: string | number;
		};

		'destinationModal.selections': {
			totalSelection: string | number;
		};

		'destinationModal.currentLocation': never;

		'destinationModal.cancel': never;

		'tag.hidden': never;

		'tag.tooltip.invalidTimeframeProperty': never;

		'tag.tooltip.noTimeframeProperty': never;

		'tag.tooltip.studioC': never;

		'tag.tooltip.resi': never;

		'tag.tooltip.noStudioCUrl': never;

		'tag.expired': never;
	};

	forms: {
		'forms.error': never;

		'forms.requiredText': never;

		'forms.validation.emailAddress': never;

		'forms.validation.generic': never;

		'forms.validation.range': {
			minimum: string | number;
			maximum: string | number;
		};

		'forms.validation.regularExpression': never;

		'forms.validation.required': never;

		'forms.validation.stringLength': {
			minimumLength: string | number;
			maximumLength: string | number;
		};

		'forms.validation.stringLengthMaxOnly': {
			maximumLength: string | number;
		};

		'forms.validation.url': never;

		'forms.validation.httpsUrl': never;

		'forms.validation.key': never;

		'forms.validation.date': never;

		'forms.validation.notPastDate': never;

		'forms.validation.notFutureDate': never;

		'forms.validation.phoneNumber': never;

		'forms.validation.arrayLength': {
			minimumLength: string | number;
			maximumLength: string | number;
		};

		'forms.validation.arrayLengthMinOnly': {
			minimumLength: string | number;
		};

		'forms.validation.amountOutOfRange': never;

		'forms.validation.panPrevention': never;
	};

	profile: {
		pageTitle: never;

		cancel: never;

		edit: never;

		update: never;

		'personalDetailsSection.title': never;

		'personalDetailsSection.firstName': never;

		'personalDetailsSection.lastName': never;

		'personalDetailsSection.emailAddress': never;

		'personalDetailsSection.unconfirmed': never;

		'personalDetailsSection.updateEmailAddress': never;

		'personalDetailsSection.emailSent': never;

		'personalDetailsSection.pleaseFollowTheLink': never;

		'personalDetailsSection.unableToLoadPersonalDetails': never;

		'locationSection.title': never;

		'locationSection.timeZone': never;

		'locationSection.timeZoneOffsetValue': {
			offset: string | number;
			timezone: string | number;
		};

		'locationSection.timeZoneTooltip': never;

		'locationSection.unableToLoadLocation': never;

		'saveButton.text.idle': never;

		'saveButton.text.saving': never;

		'saveButton.text.success': never;
	};
};

export type NamespaceKey = keyof TranslationLanguage;

export type Translate<TNamespaceKey extends NamespaceKey> = <TKey extends keyof TranslationLanguage[TNamespaceKey]>(
	key: TKey,
	...params: TranslationLanguage[TNamespaceKey][TKey] extends never
		? [undefined?]
		: [TranslationLanguage[TNamespaceKey][TKey]]
) => string;

/**
 * A utility hook for interacting with i18n. Caters for all basic functionality such as translation, changing language, listening to
 * language change etc.
 *
 * If you want to add translation post processors or require additional functionality eg. translate Moment create an index file with
 * your own hook that calls this hook internally
 */
export function useTranslation<TNamespaceKey extends NamespaceKey>(
	namespace: TNamespaceKey,
	options?: UseTranslationOptions,
	translationPostProcessors: ((text: string) => string)[] = []
) {
	const { t, i18n, ready } = useTranslationI18next(namespace, options);
	const { language, changeLanguage: changeLanguageI18n } = i18n;
	const translate = React.useCallback<Translate<TNamespaceKey>>(
		function translateInner(key, ...params) {
			const translatedText = t(key as any, ...(params as any)) as string;
			return translationPostProcessors.reduce((text, processor) => processor(text), translatedText);
		},
		[t, translationPostProcessors]
	);
	const keyExists = React.useCallback((key: string | string[]) => i18n.exists(`${namespace}א${key}`), [
		namespace,
		i18n,
	]);
	const changeLanguage = React.useCallback((newLanguage: string | undefined) => changeLanguageI18n.bind(i18n)(newLanguage), [i18n]);
	const onLanguageChange = React.useCallback(
		(callback: (language: string) => void) => {
			i18n.on('languageChanged', callback);
			return () => i18n.off('languageChanged', callback);
		},
		[i18n]
	);
	const translateMoment = React.useCallback(
		(value: Moment, displayFormat = 'DD MMM YYYY') =>
			value
				.clone()
				.locale(language)
				.format(displayFormat),
		[language]
	);

	return React.useMemo(
		() => ({
			translate,
			keyExists,
			language,
			changeLanguage,
			onLanguageChange,
			translateMoment,
			i18n,
			ready,
		}),
		[translate, keyExists, language, changeLanguage, onLanguageChange, translateMoment, i18n, ready]
	);
}

export type TransProps<
	TNamespaceKey extends NamespaceKey,
	TKey extends keyof TranslationLanguage[TNamespaceKey]
> = Omit<TransPropsI18next<never>, 'i18nKey' | 'values' | 'ns'> & {
	i18nKey: TKey;
	namespace?: TNamespaceKey;
} & (TranslationLanguage[TNamespaceKey][TKey] extends never
		? { values?: undefined }
		: { values: TranslationLanguage[TNamespaceKey][TKey] });

export const getTransWithDefaultNamespace = <TDefaultNamespace extends NamespaceKey>(
	_defaultNamespace: TDefaultNamespace
) => <TKey extends keyof TranslationLanguage[TNamespaceKey], TNamespaceKey extends NamespaceKey = TDefaultNamespace>({
	i18nKey,
	namespace,
	...rest
}: TransProps<TNamespaceKey, TKey>) => {
	useTranslationI18next();
	return <TransI18next ns={namespace} i18nKey={i18nKey as any} {...rest} />;
};
