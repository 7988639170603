import { useEffect } from 'react';

import BaseNotification from '@pushpay/notification';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps, memo } from '@pushpay/types';

import { NotificationState } from './types';

const useStyles = createUseStyles((theme: Theme) => ({
	title: {
		color: theme.colors['color-text-default'],
		font: theme.typography['text-4-semiBold'],
	},
	message: {
		color: theme.colors['color-text-default'],
		font: theme.typography['text-4'],
	},
	root: {
		position: 'absolute',
	},
	icon: {
		marginRight: theme.SPACING.XSMALL,
	},
	warning: {
		color: theme.colors['yellow-400'],
	},
}));

type NotificationProps = {
	notificationState: NotificationState;
	dismiss: () => void;
} & Pick<React.ComponentProps<typeof BaseNotification>, 'wrapper'>;

type NotificationComponentProps = ComponentProps<NotificationProps, typeof useStyles>;
const notificationLifetime = 5000;

export const Notification = memo(
	({
		notificationState,
		dismiss,
		wrapper,
		classes: classesProp,
		'data-pp-at-target': targetId,
	}: NotificationComponentProps) => {
		const classes = useStyles(classesProp);
		const { title, content, isOpen = true, type = 'success', persistent } = notificationState;

		useEffect(() => {
			if (isOpen && !persistent) {
				const dismissTimeoutId = window.setTimeout(dismiss, notificationLifetime);
				return () => {
					window.clearTimeout(dismissTimeoutId);
				};
			}
			return undefined;
		}, [notificationState, isOpen, dismiss, persistent]);

		return (
			<BaseNotification
				classes={{
					root: classes.root,
					icon: classes.icon,
					warning: classes.warning,
					title: classes.title,
					message: classes.message,
				}}
				closeButtonAriaLabel="Close"
				data-pp-at-target={targetId}
				dismiss={dismiss}
				isOpen={isOpen}
				title={title}
				type={type}
				wrapper={wrapper}
			>
				{content}
			</BaseNotification>
		);
	}
);
