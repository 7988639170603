import { ContainerType, ItemType } from '@src/graphql/generated';

export const ContainerCardType = {
	CCB: 'ccb',
	Resi: 'resi',
	StudioC: 'studioC',
	Container: 'container',
	Item: 'item',
} as const;

export type ContainerCardType = (typeof ContainerCardType)[keyof typeof ContainerCardType];

export const intergretionCardTypes = [ContainerCardType.Resi, ContainerCardType.StudioC];

export function getItemCardType(itemType?: ItemType): ContainerCardType {
	switch (itemType) {
		case ItemType.CcbGroup:
		case ItemType.CcbGroups:
			return ContainerCardType.CCB;
		default:
			return ContainerCardType.Item;
	}
}

export function getContainerCardType(containerType?: ContainerType): ContainerCardType {
	switch (containerType) {
		case ContainerType.CcbCheckIn:
		case ContainerType.CcbGroups:
		case ContainerType.CcbPublicNeeds:
		case ContainerType.CcbServing:
			return ContainerCardType.CCB;
		case ContainerType.Resi:
			return ContainerCardType.Resi;
		case ContainerType.StudioC:
			return ContainerCardType.StudioC;
		default:
			return ContainerCardType.Container;
	}
}
