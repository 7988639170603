import { useMemo } from 'react';

import { useFeature } from '@src/featureFlags';
import { ContainerTemplate, ContainerType, ShellCapabilityKey } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { Draggable, DraggableContainerData } from '../types';

export const useDraggableContainersDefinition = () => {
	const { translate } = useTranslation('appDesign');
	const isStudioCIntegrationEnabled = useFeature('StudioCIntegration');

	const addContainer: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'folder-1',
			label: 'Container',
			name: 'add_container',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						icon: 'folder-1',
						type: ContainerType.Default,
						name: translate('defaultValue.containerDefinition.defaultContainerName'),
						template: ContainerTemplate.List_72Dp,
					},
				},
			],
			unique: false,
			type: 'container',
		}),
		[translate]
	);

	const addContainerEvents: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'calendar-2',
			label: 'Events Container',
			name: 'add_container_events',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						type: ContainerType.Events,
						name: translate('defaultValue.containerDefinition.eventsContainerName'),
						template: ContainerTemplate.List_90DpSectionLegacy,
						icon: 'calendar-2',
					},
				},
			],
			unique: false,
			type: 'container',
		}),
		[translate]
	);

	const addContainerGive: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'heart-1',
			label: 'Give',
			name: 'add_container_give',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						name: translate('defaultValue.containerDefinition.giveContainerName'),
						type: ContainerType.Give,
						icon: 'heart-1',
						template: ContainerTemplate.List_72Dp,
					},
				},
			],
			unique: true,
			type: 'container',
		}),
		[translate]
	);

	const addContainerMessages: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'bubble-conversation-4',
			label: 'Messages',
			name: 'add_container_messages',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						name: translate('defaultValue.containerDefinition.messageContainerName'),
						type: ContainerType.Messages,
						template: ContainerTemplate.List_72DpMessages,
						icon: 'bubble-conversation-4',
					},
				},
			],
			unique: true,
			type: 'container',
		}),
		[translate]
	);
	const addContainerPlaces: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'map-pin',
			label: 'Places',
			name: 'add_container_places',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						name: translate('defaultValue.containerDefinition.placesContainerName'),
						type: ContainerType.Places,
						template: ContainerTemplate.List_72DpTidbit,
						icon: 'map-pin',
					},
				},
			],
			unique: true,
			type: 'container',
		}),
		[translate]
	);
	const addContainerCCBCheckIn: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'check-circle-2',
			label: 'Check-In',
			name: 'ccb_check_in',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						name: translate('defaultValue.containerDefinition.ccbCheckInContainer'),
						type: ContainerType.CcbCheckIn,
						icon: 'check-circle-2',
						relatedContentOn: false,
						template: ContainerTemplate.List_72Dp,
					},
				},
			],
			unique: true,
			type: 'container',
			category: 'ChMS',
			requiredShellCapability: ShellCapabilityKey.ChmsDraggables,
		}),
		[translate]
	);
	const addContainerCCBGroups: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'connection-3',
			label: 'Groups',
			name: 'ccb_groups',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						name: translate('defaultValue.containerDefinition.ccbGroupsContainer'),
						type: ContainerType.CcbGroups,
						icon: 'connection-3',
						relatedContentOn: false,
						template: ContainerTemplate.List_72Dp,
					},
				},
			],
			unique: true,
			type: 'container',
			category: 'ChMS',
			requiredShellCapability: ShellCapabilityKey.ChmsDraggables,
		}),
		[translate]
	);
	const addContainerCCBServing: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'hand-grab-2',
			label: 'Serving',
			name: 'ccb_serving',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						name: translate('defaultValue.containerDefinition.ccbServingContainer'),
						type: ContainerType.CcbServing,
						icon: 'hand-grab-2',
						relatedContentOn: false,
						template: ContainerTemplate.List_72Dp,
					},
				},
			],
			unique: true,
			type: 'container',
			category: 'ChMS',
			requiredShellCapability: ShellCapabilityKey.ChmsDraggables,
		}),
		[translate]
	);
	const addContainerCCBPublicNeeds: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'check-list',
			label: 'Public Needs',
			name: 'ccb_public_needs',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						name: translate('defaultValue.containerDefinition.ccbPublicNeeds'),
						type: ContainerType.CcbPublicNeeds,
						icon: 'check-list',
						relatedContentOn: false,
						template: ContainerTemplate.List_72Dp,
					},
				},
			],
			unique: true,
			type: 'container',
			category: 'ChMS',
			requiredShellCapability: ShellCapabilityKey.ChmsDraggables,
		}),
		[translate]
	);
	const addContainerSelectCampus: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'column',
			label: 'Select campus',
			name: 'add_container_select_campus',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						name: translate('defaultValue.containerDefinition.campusContainerName'),
						type: ContainerType.SelectCampus,
						icon: 'column',
						template: ContainerTemplate.List_72Dp,
					},
				},
			],
			unique: true,
			type: 'container',
		}),
		[translate]
	);
	const addContainerResiLibrary: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'media-library',
			label: isStudioCIntegrationEnabled ? translate('draggables.resiDraggableLabel') : 'Media Library',
			name: 'add_container_resi_library',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						name: translate('defaultValue.containerDefinition.resiLibrary'),
						type: ContainerType.Resi,
						icon: 'media-library',
						template: ContainerTemplate.Unknown,
						relatedContentOn: false,
					},
				},
			],
			unique: true,
			type: 'container',
			category: 'Resi',
			requiredShellCapability: ShellCapabilityKey.ResiVodDraggable,
		}),
		[translate, isStudioCIntegrationEnabled]
	);
	const addContainerStudioC: Draggable<DraggableContainerData> = useMemo(
		() => ({
			icon: 'house-2',
			label: translate('draggables.studioCDraggable'),
			name: 'add_container_studio_c',
			actions: [
				{
					action_type: 'create',
					model: 'container',
					data: {
						name: translate('defaultValue.containerDefinition.studioC'),
						type: ContainerType.StudioC,
						icon: 'media-library',
						template: ContainerTemplate.Unknown,
						typeSpecificSettings: {
							ctaLabel: translate('defaultValue.studioC.ctaLabel'),
							title: translate('defaultValue.studioC.title'),
							description: translate('defaultValue.studioC.description'),
						},
						relatedContentOn: false,
					},
				},
			],
			unique: true,
			type: 'container',
			category: 'StudioC',
			requiredShellCapability: ShellCapabilityKey.ResiVodDraggable,
		}),
		[translate]
	);
	return {
		addContainer,
		addContainerEvents,
		addContainerGive,
		addContainerMessages,
		addContainerPlaces,
		addContainerCCBCheckIn,
		addContainerCCBGroups,
		addContainerCCBServing,
		addContainerCCBPublicNeeds,
		addContainerSelectCampus,
		addContainerResiLibrary,
		addContainerStudioC,
	};
};
