import { useState } from 'react';

import { ActionButton } from '@pushpay/button';
import Dropdown from '@pushpay/dropdown';
import { EyeClosedIcon, KebabIcon, PenIcon } from '@pushpay/iconography';
import { clsx } from '@pushpay/styles';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { KebabMenu } from '@src/components/kebabMenu';
import { Link } from '@src/components/link';
import { DeleteModal } from '@src/components/modals';
import { ContentType, useBreadcrumbStateContext, useCopyAndMoveDispatchContext } from '@src/context';
import { useAppDesignPath } from '@src/pages/shell';
import { useParams } from '@src/router';
import { useIsOverflowed } from '@src/utils';
import { ContainerCardType } from '@src/utils/cardType';

import { useStyles } from './bannerStyles';

export type BannerProps = ComponentProps<
	{
		name: string;
		isHidden: boolean;
		type?: ContainerCardType;
		isEditable: boolean;
		isFeedItem?: boolean;
		isDeletable?: boolean;
		editPath?: string;
		hideVisibilityToggle?: boolean;
		contentType?: ContentType;
		isSticky?: boolean;
		onDelete?: () => void;
		onToggleVisibility?: (isHidden: boolean) => void;
		hasInvalidTimeframeProperties?: boolean;
		hasNoTimeframeProperty?: boolean;
	},
	typeof useStyles
>;

export const Banner = ({
	name,
	isHidden,
	type,
	isEditable,
	isDeletable,
	isFeedItem,
	editPath = '',
	hideVisibilityToggle,
	contentType,
	isSticky = false,
	onDelete,
	onToggleVisibility,
	'data-pp-at-target': targetId,
	hasInvalidTimeframeProperties = false,
	hasNoTimeframeProperty = false,
}: BannerProps) => {
	const classes = useStyles(undefined);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [nameRef, isNameOverflowed] = useIsOverflowed();
	const { organizationKey } = useParams<'organizationKey'>();
	const bannerEditPath = useAppDesignPath(organizationKey, editPath);
	const { breadcrumbs } = useBreadcrumbStateContext();
	const parentBreadcrumbIndex = breadcrumbs.length - (contentType === ContentType.CONTAINER_SETTING ? 3 : 2);
	const bannerParentPath = breadcrumbs[parentBreadcrumbIndex]?.path;
	const [isTitleHover, setIsTitleHover] = useState(false);
	const { getItemCount } = useCopyAndMoveDispatchContext();
	const hasSelectedItems = getItemCount() > 0;

	let onDeleteClose = () => {};

	const toggleHide = () => {
		if (onToggleVisibility) {
			onToggleVisibility(!isHidden);
		}
	};
	return (
		<div className={clsx(classes.banner, isSticky && classes.sticky)}>
			<div className={classes.container}>
				<div className={classes.nameContainer}>
					<Tooltip
						className={classes.tooltipRoot}
						content={name}
						data-pp-at-target={`${targetId}-name`}
						disabled={!isNameOverflowed}
						panelSpace="XSMALL"
						placement="bottom"
					>
						{isEditable ? (
							<Link
								className={classes.containerName}
								displayStyle="unstyled"
								to={bannerEditPath}
								onMouseOut={() => setIsTitleHover(false)}
								onMouseOver={() => setIsTitleHover(true)}
							>
								<div ref={nameRef} className={classes.link}>
									{name}
								</div>
								{isTitleHover && (
									<div className={classes.editIcon}>
										<PenIcon />
									</div>
								)}
							</Link>
						) : (
							<div ref={nameRef} className={classes.name}>
								{name}
							</div>
						)}
					</Tooltip>
				</div>
				{isHidden && (
					<Tooltip
						content={`Hidden ${type}`}
						data-pp-at-target={`${targetId}-hidden-tooltip`}
						panelSpace="XSMALL"
						placement="top"
						title="hidden"
					>
						<EyeClosedIcon
							classes={{ root: classes.hiddenIcon }}
							data-pp-at-target={`${targetId}-hidden-icon`}
						/>
					</Tooltip>
				)}
			</div>
			{name && type !== undefined && (
				<>
					<Dropdown
						classes={{ summary: classes.kebabContainer }}
						closeOnBlur={!isModalOpen}
						data-pp-at-target={`${targetId}-dropdown`}
						disabled={hasSelectedItems}
						lockFocus={!isModalOpen}
						overlay={close => {
							onDeleteClose = close;
							return (
								<KebabMenu
									allowCopyMove={false}
									data-pp-at-target={`${targetId}-kebab-menu`}
									editPath={bannerEditPath}
									hasInvalidTimeframeProperties={hasInvalidTimeframeProperties}
									hasNoTimeframeProperty={hasNoTimeframeProperty}
									hideVisibilityToggle={hideVisibilityToggle}
									isDeletable={isDeletable}
									isEditable={isEditable}
									isFeedItem={isFeedItem}
									isHidden={isHidden}
									setIsModalOpen={setIsModalOpen}
									toggleHide={toggleHide}
									type={type}
								/>
							);
						}}
						hideDropdownArrow
					>
						<ActionButton
							aria-label="Action Menu"
							className={classes.kebab}
							data-pp-at-target={`${targetId}-action-button`}
							disabled={hasSelectedItems}
							displayStyle="secondary"
							icon={KebabIcon}
							tabIndex={-1}
						/>
					</Dropdown>
					<DeleteModal
						isDeletable={isDeletable}
						isModalOpen={isModalOpen}
						parentPath={bannerParentPath}
						setIsModalOpen={setIsModalOpen}
						translatedCardType={type}
						onDelete={() => {
							if (onDelete) {
								onDelete();
							}
							onDeleteClose();
						}}
					/>
				</>
			)}
		</div>
	);
};
