import { defaultMutateRefetchQueries } from '@src/graphql/client';
import { GetContainerSettingsQuery, GetContainerVisibilityDocument } from '@src/graphql/generated';

export const getRefetchQueries = (
	containerSettings: NonNullable<NonNullable<GetContainerSettingsQuery['organization']>['application']>['container'],
	organizationKey: string,
	applicationId: string,
	platformCampusKey?: string | null
) => {
	const getContainerChildrenQuery = {
		query: GetContainerVisibilityDocument,
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId: containerSettings?.id ?? '',
		},
	};

	return [...defaultMutateRefetchQueries, getContainerChildrenQuery];
};
