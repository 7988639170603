import { TextField, Field } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { useTranslation } from '@src/i18n/translation';
import { validateUrl } from '@src/utils';

const useStyle = createUseStyles({
	label: {},
	root: {},
});

export type UrlFieldProps = ComponentProps<
	{
		field: Field<string>;
		httpsOnly?: boolean;
	} & React.ComponentProps<typeof TextField>,
	typeof useStyle
>;

export function UrlField({
	field,
	httpsOnly = true,
	classes: classesProp,
	customValidationFailureMessage,
	...rest
}: UrlFieldProps) {
	const classes = useStyle(classesProp);
	const { translate } = useTranslation('forms');
	const urlValidationMessage = httpsOnly ? translate('forms.validation.httpsUrl') : translate('forms.validation.url');

	const finalCustomValidationFailureMessage =
		field.value && !validateUrl(field.value, httpsOnly)
			? urlValidationMessage
			: customValidationFailureMessage ?? undefined;

	return (
		<TextField
			classes={classes}
			field={field}
			{...rest}
			customValidationFailureMessage={finalCustomValidationFailureMessage}
		/>
	);
}
