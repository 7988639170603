import { useRef } from 'react';

import Notification from '@pushpay/notification';
import { createUseStyles, subtract } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { Link } from '@src/components/link';
import { Trans, useTranslation } from '@src/i18n';

const useStyles = createUseStyles((theme: Theme) => ({
	container: {
		marginTop: theme.SPACING.LARGE,
		position: 'relative',
		width: subtract('275px', theme.SPACING.XSMALL),
		[theme.mediaBreakpoint('DESKTOP_AND_BELOW')]: {
			width: subtract('240px', theme.SPACING.XXSMALL),
			marginTop: theme.SPACING.MEDIUM,
		},
	},
	notification: {
		position: 'relative',
		padding: theme.SPACING.XXSMALL,
		zIndex: 0,
		color: theme.colors['blue-500'],
		backgroundColor: theme.colors['blue-100'],
	},
	content: {
		margin: theme.SPACING.SMALL,
		alignItems: 'start',
	},
	icon: {
		marginRight: theme.SPACING.SMALL_ICON_SIZE,
		marginTop: '2px',
	},
	message: {
		paddingRight: 0,
		font: theme.typography['text-4'],
		'& > strong': {
			font: theme.typography['text-4-semiBold'],
		},
		'& > a': {
			color: theme.colors['blue-600'],
			font: theme.typography['text-4'],
		},
	},
}));

export function OutdatedShellBanner() {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');
	const wrapperRef = useRef(null);

	return (
		<div ref={wrapperRef} className={classes.container}>
			<Notification
				classes={{
					root: classes.notification,
					icon: classes.icon,
					content: classes.content,
					message: classes.message,
				}}
				closeButtonAriaLabel=""
				title={translate('preview.shellUpdateInfo')}
				type="info"
				wrapper={wrapperRef}
				isOpen
			>
				<Trans i18nKey="preview.outdatedShellBanner" namespace="appDesign">
					Preview may differ from the app if you&apos;re using a shell version below 12.
					<Link href="https://pushpay.formstack.com/forms/app_resubmission_request" target="_blank">
						Request an app upgrade
					</Link>
				</Trans>
			</Notification>
		</div>
	);
}
