import QRCode from 'qrcode';

export const generateQRWithLogo = async (qrCodeText: string, logoBase64: string) => {
	const size = 400;
	const qrDataUrl = await QRCode.toDataURL(qrCodeText, {
		width: size,
		errorCorrectionLevel: 'H',
	});
	const totalLogoSize = size * 0.2; // Logo + border together
	const borderSize = totalLogoSize * 0.05; // 15% of total size as border
	const logoSize = totalLogoSize - borderSize * 2; // Adjusted logo size
	const borderRadius = 8; // Rounded corners

	const qrImage = new Image();
	qrImage.src = qrDataUrl;

	const logoImage = new Image();
	logoImage.src = logoBase64;

	return new Promise<string>(resolve => {
		qrImage.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			if (!ctx) return;

			canvas.width = size;
			canvas.height = size;

			// Draw the QR code
			ctx.drawImage(qrImage, 0, 0, size, size);
			// Define positions
			const logoX = size / 2 - totalLogoSize / 2;
			const logoY = size / 2 - totalLogoSize / 2;

			// Draw rounded white background (border)
			ctx.fillStyle = 'white';
			ctx.beginPath();
			ctx.moveTo(logoX + borderRadius, logoY);
			ctx.lineTo(logoX + totalLogoSize - borderRadius, logoY);
			ctx.quadraticCurveTo(logoX + totalLogoSize, logoY, logoX + totalLogoSize, logoY + borderRadius);
			ctx.lineTo(logoX + totalLogoSize, logoY + totalLogoSize - borderRadius);
			ctx.quadraticCurveTo(
				logoX + totalLogoSize,
				logoY + totalLogoSize,
				logoX + totalLogoSize - borderRadius,
				logoY + totalLogoSize
			);
			ctx.lineTo(logoX + borderRadius, logoY + totalLogoSize);
			ctx.quadraticCurveTo(logoX, logoY + totalLogoSize, logoX, logoY + totalLogoSize - borderRadius);
			ctx.lineTo(logoX, logoY + borderRadius);
			ctx.quadraticCurveTo(logoX, logoY, logoX + borderRadius, logoY);
			ctx.closePath();
			ctx.fill();

			// Create an offscreen canvas to clip the logo with rounded corners
			const logoCanvas = document.createElement('canvas');
			const logoCtx = logoCanvas.getContext('2d');
			if (!logoCtx) return;

			logoCanvas.width = logoSize;
			logoCanvas.height = logoSize;

			// Clip the logo with rounded corners
			logoCtx.fillStyle = 'white';
			logoCtx.beginPath();
			logoCtx.moveTo(borderRadius, 0);
			logoCtx.lineTo(logoSize - borderRadius, 0);
			logoCtx.quadraticCurveTo(logoSize, 0, logoSize, borderRadius);
			logoCtx.lineTo(logoSize, logoSize - borderRadius);
			logoCtx.quadraticCurveTo(logoSize, logoSize, logoSize - borderRadius, logoSize);
			logoCtx.lineTo(borderRadius, logoSize);
			logoCtx.quadraticCurveTo(0, logoSize, 0, logoSize - borderRadius);
			logoCtx.lineTo(0, borderRadius);
			logoCtx.quadraticCurveTo(0, 0, borderRadius, 0);
			logoCtx.closePath();
			logoCtx.fill();

			// Draw the logo onto the clipped area
			logoCtx.globalCompositeOperation = 'source-in';
			logoCtx.drawImage(logoImage, 0, 0, logoSize, logoSize);

			// Once the logo is loaded, draw it onto the main canvas
			logoImage.onload = () => {
				ctx.drawImage(logoCanvas, logoX + borderSize, logoY + borderSize);

				resolve(canvas.toDataURL('image/png'));
			};
		};
	});
};
