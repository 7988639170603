/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-use-before-define */

export interface PossibleTypesResultData {
	possibleTypes: {
		[key: string]: string[];
	};
}
const result: PossibleTypesResultData = {
	possibleTypes: {
		Card: ['AudioCard', 'DefaultCard', 'EventCard', 'ImpactCard', 'IntroCard', 'SpecialCard', 'VideoCard'],
		ContainerChild: ['ChildContainer', 'ChildItem'],
		ContainerTypeSpecificSettings: ['StudioCContainerTypeSettings'],
		CopyItemResult: ['CopyItemError', 'CopyItemSuccess'],
		CopyItemsResult: ['CopyItemsOutput', 'InvalidRequestError', 'RootContainerError'],
		DeleteContainerNewResult: [
			'ContainerNotEmptyError',
			'DeleteContainerNewSuccess',
			'InvalidRequestError',
			'RootContainerError',
		],
		Error: [
			'ContainerNotEmptyError',
			'CopyItemError',
			'InvalidRequestError',
			'MoveChildError',
			'RootContainerError',
		],
		Feed: ['IcalFeed', 'PodcastFeed', 'RssFeed', 'VideoFeed', 'VimeoFeed', 'YouTubeFeed'],
		IValidationError: ['InvalidStudioCLinkError'],
		ItemCardDefinition: ['BaseCardDefinition', 'ImpactCardDefinition'],
		ItemProperty: [
			'AddToContactsProperty',
			'AddressProperty',
			'AppLinkProperty',
			'AudioProperty',
			'BlankifyProperty',
			'CallToActionProperty',
			'CarouselProperty',
			'DefaultProperty',
			'GiveProperty',
			'KeyMetricsProperty',
			'ShareProperty',
			'SmsProperty',
			'TextHtmlProperty',
			'TextProperty',
			'TimeframeProperty',
			'UserNoteProperty',
			'VideoProperty',
			'WebsiteProperty',
		],
		MoveChildResult: ['MoveChildError', 'MoveChildSuccess'],
		MoveChildrenResult: ['InvalidRequestError', 'MoveChildrenOutput', 'RootContainerError'],
		MovedChild: ['Container', 'Item'],
		SaveContainerSettingsResult: ['SaveContainerSettingsSuccess', 'ValidationErrors'],
	},
};
export default result;
